function Footer() {
    return (
    <footer className="row">
		<div className="col-md-12 funbusi_linkbar">
			<p>Copyright &copy; 2022 Funbusi</p>
		</div>
	</footer>
    )
}

export default Footer;